import useRouter from '@finst/core/src/scripts/components/router/hooks/use-router';
import useAsync from '@finst/core/src/scripts/hooks/use-async';
import { getAllCookies } from '@finst/core/src/scripts/utils/platform/cookies';
const emptyParams = {};
export default function useRegistrationUrlParams() {
    const { location } = useRouter();
    // set only once
    const { value: params = emptyParams } = useAsync(async ()=>{
        if (location.pathname !== '/identity/login/email') {
            return {};
        }
        const urlParams = new URLSearchParams(location.search);
        const cookieParams = await getAllCookies().then((cookies)=>Object.entries(cookies).map(([name, value])=>{
                const prefix = '_landingParam__';
                if (value && name.startsWith(prefix)) {
                    return [
                        name.replace(prefix, ''),
                        value
                    ];
                }
            }).filter((entry)=>entry !== undefined)).catch(()=>[]);
        return Object.fromEntries([
            ...cookieParams,
            ...urlParams.entries()
        ]);
    }, []);
    return params;
}
