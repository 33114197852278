// extracted by mini-css-extract-plugin
export var borderlessStepFooter = "drzg8Ikg";
export var finishedProgressStepTitleIcon = "nqMWeRv4";
export var stepContentSection = "ZGjuGZKW";
export var stepDescription = "M7HqWg15";
export var stepFooter = "A5DTOlBE";
export var stepFooterButton = "baLgUZH0";
export var stepFooterHint = "am694LNp";
export var stepForm = "RnSNYN1S";
export var stepFormAlert = "qYba_8TI";
export var stepFormButton = "Zoyypkpf";
export var stepFormInput = "ZkSYjyFZ";
export var stepFormMessage = "Kw747_gd";