// Cache pending promise to avoid multiple requests.
let pendingPromise;
export default async function getClientEnvironmentCountryCode() {
    var _response;
    if (!pendingPromise) {
        pendingPromise = fetch(`/favicon.ico?requestReason=clientCountryCodeCheck&t=${Date.now()}`);
    }
    const response = await pendingPromise.catch(()=>{
        pendingPromise = undefined;
    });
    return ((_response = response) === null || _response === void 0 ? void 0 : _response.clone().headers.get('X-Client-Country-Code')) || undefined;
}
