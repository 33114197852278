import { useCallback, useState } from 'preact/compat';
export default function useValueSwitch(initialValue) {
    const [value, setValue] = useState(initialValue);
    const resetValue = useCallback(()=>setValue(initialValue), [
        initialValue
    ]);
    return {
        value,
        setValue,
        resetValue
    };
}
