import { useMemo } from 'preact/compat';
import useDeviceInfo from '../../../../hooks/use-device-info';
export default function useMultipleAccountsFeatureFlag() {
    var _window_cordova_plugins, _window_cordova;
    const cordovaAppInfo = (_window_cordova = window.cordova) === null || _window_cordova === void 0 ? void 0 : (_window_cordova_plugins = _window_cordova.plugins) === null || _window_cordova_plugins === void 0 ? void 0 : _window_cordova_plugins.AppInfo;
    const deviceInfo = useDeviceInfo();
    // Wait when device info will be initialized
    return useMemo(()=>{
        var _deviceInfo, _deviceInfo1;
        if (((_deviceInfo = deviceInfo) === null || _deviceInfo === void 0 ? void 0 : _deviceInfo.platform) === 'Android') {
            return true;
        }
        if (((_deviceInfo1 = deviceInfo) === null || _deviceInfo1 === void 0 ? void 0 : _deviceInfo1.platform) === 'iOS' && cordovaAppInfo) {
            const [major, minor] = cordovaAppInfo.version.split('.');
            // iOS app v1.8.0 is the first version with multiple accounts feature
            return Number(major) >= 2 || Number(major) === 1 && Number(minor) >= 8;
        }
        return false;
    }, [
        deviceInfo,
        cordovaAppInfo
    ]);
}
