import normalizeError from '@finst/core/src/scripts/services/app-error/normalize-error';
import createCancellablePromise from '@finst/core/src/scripts/utils/async/create-cancellable-promise';
import { useCallback, useEffect, useReducer, useRef } from 'preact/compat';
import { expectedPinCodeLength } from '../../../models/identity';
function reducer(_prevState, action) {
    if (action.type === 'INPUT') {
        return {
            isSubmitting: false,
            pinCode: action.payload,
            error: undefined
        };
    }
    if (action.type === 'SUBMIT') {
        return {
            isSubmitting: true,
            pinCode: action.payload,
            error: undefined
        };
    }
    if (action.type === 'SUCCESS') {
        return {
            isSubmitting: false,
            pinCode: action.payload,
            error: undefined
        };
    }
    if (action.type === 'ERROR') {
        return {
            isSubmitting: false,
            pinCode: '',
            error: action.payload
        };
    }
    return {
        isSubmitting: false,
        pinCode: '',
        error: undefined
    };
}
export default function usePinCodeFormSubmit(submit) {
    const submitRequestRef = useRef(undefined);
    const [state, dispatch] = useReducer(reducer, {
        isSubmitting: false,
        pinCode: '',
        error: undefined
    });
    const handleInput = useCallback((pinCode)=>{
        var _submitRequestRef_current;
        pinCode = pinCode.trim().slice(0, expectedPinCodeLength);
        if (pinCode.length !== expectedPinCodeLength) {
            dispatch({
                type: 'INPUT',
                payload: pinCode
            });
            return;
        }
        dispatch({
            type: 'SUBMIT',
            payload: pinCode
        });
        (_submitRequestRef_current = submitRequestRef.current) === null || _submitRequestRef_current === void 0 ? void 0 : _submitRequestRef_current.cancel();
        submitRequestRef.current = createCancellablePromise(submit(pinCode));
        submitRequestRef.current.promise.then(()=>dispatch({
                type: 'SUCCESS',
                payload: pinCode
            })).catch((error)=>dispatch({
                type: 'ERROR',
                payload: normalizeError(error)
            }));
    }, [
        submit
    ]);
    const clearError = useCallback(()=>dispatch({
            type: 'RESET'
        }), []);
    useEffect(()=>{
        return ()=>{
            var _submitRequestRef_current;
            (_submitRequestRef_current = submitRequestRef.current) === null || _submitRequestRef_current === void 0 ? void 0 : _submitRequestRef_current.cancel();
            submitRequestRef.current = undefined;
        };
    }, []);
    return {
        ...state,
        handleInput,
        clearError
    };
}
