import useAsyncFn from '@finst/core/src/scripts/hooks/use-async-fn';
import { useCallback, useMemo } from 'preact/compat';
import { mainModuleId, supportedLanguages } from '../models/i18n';
import getTranslations from '../services/i18n/get-translations';
import useAppSettings from './use-app-settings';
import useTranslations from './use-translations';
export default function useLanguageSwitch() {
    const { language, update: updateAppSettings } = useAppSettings();
    const { update: updateTranslations } = useTranslations();
    const selectedLanguage = useMemo(()=>supportedLanguages.find((lang)=>lang.code === language), [
        language
    ]);
    const { isLoading, act: handleLanguageChange } = useAsyncFn(useCallback(async (event)=>{
        const language = event.currentTarget.value;
        const translations = await getTranslations({
            language,
            modules: [
                mainModuleId
            ]
        });
        updateAppSettings({
            language
        });
        updateTranslations(translations);
    }, [
        updateAppSettings,
        updateTranslations
    ]));
    return {
        isLoading,
        selectedLanguage,
        languages: supportedLanguages,
        handleLanguageChange
    };
}
