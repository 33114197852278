import useAsyncFn from '@finst/core/src/scripts/hooks/use-async-fn';
import cancelQrCodeScanner from '@finst/core/src/scripts/services/qr-code-scanner/cancel-qr-code-scanner';
import initQrCodeScanner from '@finst/core/src/scripts/services/qr-code-scanner/init-qr-code-scanner';
import scanQrCode from '@finst/core/src/scripts/services/qr-code-scanner/scan-qr-code';
import { useCallback, useLayoutEffect } from 'preact/compat';
import getAppRootElement from '../../../get-app-root-element';
export default function useQrCodeScanner() {
    const { value, error, isLoading, act: scan, cancel: cancelScanRequest } = useAsyncFn(useCallback(async ()=>{
        await initQrCodeScanner();
        return scanQrCode();
    }, []));
    const cancelScan = useCallback(()=>{
        cancelScanRequest(undefined);
        cancelQrCodeScanner();
    }, [
        cancelScanRequest
    ]);
    useLayoutEffect(()=>{
        getAppRootElement().style.opacity = isLoading ? '0' : '';
        if (!isLoading) {
            // remove QR code plugin artifacts
            document.documentElement.style.backgroundColor = '';
            document.body.style.backgroundColor = '';
        }
    }, [
        isLoading
    ]);
    return {
        isLoading,
        value,
        error,
        scan,
        cancelScan
    };
}
