export async function setCookies(cookies, options = {}) {
    const optionsString = [
        `samesite=${options.sameSite || 'lax'}`,
        options.domain ? `domain=${options.domain}` : '',
        options.path ? `path=${options.path}` : '',
        options.expires ? `expires=${options.expires.toUTCString()}` : '',
        options.partitioned ? 'partitioned' : '',
        // secure context by default
        options.secure === false ? '' : 'secure'
    ].filter((option)=>option !== '').join('; ');
    Object.entries(cookies).forEach(([name, value])=>{
        document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; ${optionsString}`;
    });
}
export async function getAllCookies() {
    const cookies = {};
    document.cookie.split('; ').forEach((cookie)=>{
        const [name, value] = cookie.split('=');
        if (name && value) {
            cookies[decodeURIComponent(name.trim())] = decodeURIComponent(value.trim());
        }
    });
    return cookies;
}
