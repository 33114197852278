import { correlationIdHeader } from '@finst/core/src/scripts/models/api';
import request from '@finst/core/src/scripts/services/api/request';
export default async function confirmIdentityQrCode(confirmation) {
    const { correlationId, ...body } = confirmation;
    await request({
        url: '/api/identity/qr-code/confirm',
        method: 'POST',
        headers: {
            [correlationIdHeader]: correlationId
        },
        body
    });
}
