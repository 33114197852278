export default function parseIdentityQrCodeValue(qrCodeValue) {
    try {
        const { token, action, correlationId } = JSON.parse(qrCodeValue);
        if (correlationId && token && action === 'qrCodeLogin') {
            return {
                token,
                action,
                correlationId
            };
        }
    } catch  {
    //
    }
}
