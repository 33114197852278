import useAsync from '@finst/core/src/scripts/hooks/use-async';
import translate from '../services/i18n/translate';
import getClientEnvironmentCountryCode from '../services/identity/get-client-environment-country-code';
import useIdentity from './use-identity';
import useTranslations from './use-translations';
export default function useRiskDisclaimer() {
    const { translations } = useTranslations();
    const { account: { countryOfResidence } } = useIdentity();
    const { isLoading, value: clientCountryCode } = useAsync(async ()=>countryOfResidence || getClientEnvironmentCountryCode(), [
        countryOfResidence
    ]);
    if (clientCountryCode !== 'BE') {
        return {
            isLoading,
            value: undefined,
            error: undefined
        };
    }
    return {
        isLoading: false,
        error: undefined,
        value: {
            title: translate(translations, 'riskDisclaimer.title'),
            badge: translate(translations, 'riskDisclaimer.badge'),
            faqPageUrl: translate(translations, 'variables.helpDesk.faq.riskDisclaimerUrl')
        }
    };
}
