import { AppError } from '../../models/app-error';
/**
 * @description This function should be used only as a utility of biometric-keychain services
 * @param {function(error: AppError):void} onError
 * @returns {function(error: string|number|Error):void}
 */ export default function createErrorHandler(onError) {
    return (error)=>{
        // Save string errors as message, e.g. 'KeyPermanentlyInvalidatedException', 'Invalid code', etc.
        // @finst/cordova-plugin-keychain-touch-id/README.md#android-quirks
        onError(typeof error === 'object' ? new AppError({
            message: error.message,
            cause: error
        }) : new AppError({
            code: error === 'KeyPermanentlyInvalidatedException' ? error : undefined,
            message: String(error)
        }));
    };
}
