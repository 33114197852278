import localLogger from '@finst/core/src/scripts/loggers/local-logger';
import normalizeError from '@finst/core/src/scripts/services/app-error/normalize-error';
import createCancellablePromise from '@finst/core/src/scripts/utils/async/create-cancellable-promise';
import { useCallback, useEffect, useRef, useState } from 'preact/compat';
export default function useFormSubmit(submit) {
    const submitLockRef = useRef(false);
    const submitRequestRef = useRef(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState();
    const handleSubmit = useCallback((event)=>{
        var _submitRequestRef_current;
        event.preventDefault();
        // prevent double submit
        if (submitLockRef.current) {
            return;
        }
        (_submitRequestRef_current = submitRequestRef.current) === null || _submitRequestRef_current === void 0 ? void 0 : _submitRequestRef_current.cancel();
        submitLockRef.current = true;
        setError(undefined);
        setIsSubmitting(true);
        submitRequestRef.current = createCancellablePromise(submit(event.currentTarget));
        submitRequestRef.current.promise.then((value)=>{
            submitLockRef.current = false;
            setIsSubmitting(false);
            return value;
        }).catch((error)=>{
            submitLockRef.current = false;
            setIsSubmitting(false);
            localLogger.error(error);
            setError(normalizeError(error));
        });
    }, [
        submit
    ]);
    const clearError = useCallback(()=>setError(undefined), []);
    useEffect(()=>{
        return ()=>{
            var _submitRequestRef_current;
            (_submitRequestRef_current = submitRequestRef.current) === null || _submitRequestRef_current === void 0 ? void 0 : _submitRequestRef_current.cancel();
            submitRequestRef.current = undefined;
        };
    }, []);
    return {
        isSubmitting,
        handleSubmit,
        error,
        clearError
    };
}
