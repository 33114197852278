import { AppError } from '../../models/app-error';
import cancelQrCodeScanner from './cancel-qr-code-scanner';
import normalizeQrScannerError from './normalize-qr-scanner-error';
export default async function scanQrCode() {
    const { QRScanner } = window;
    if (!QRScanner) {
        throw new AppError({
            code: 'Unsupported'
        });
    }
    return Promise.all([
        new Promise((resolve, reject)=>{
            QRScanner.scan((error, data)=>{
                cancelQrCodeScanner();
                if (error) {
                    reject(normalizeQrScannerError(error));
                } else {
                    resolve(data);
                }
            });
        }),
        new Promise((resolve)=>QRScanner.show(()=>resolve()))
    ]).then(([code])=>code);
}
