import useAsync from '@finst/core/src/scripts/hooks/use-async';
import deleteFromBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/delete-from-biometric-keychain';
import isBiometricKeychainAvailable from '@finst/core/src/scripts/services/biometric-keychain/is-biometric-keychain-available';
import isSavedInBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/is-saved-in-biometric-keychain';
import saveToBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/save-to-biometric-keychain';
import verifyInBiometricKeychain from '@finst/core/src/scripts/services/biometric-keychain/verify-in-biometric-keychain';
import generateId from '@finst/core/src/scripts/utils/generate-id';
import { useCallback, useState } from 'preact/compat';
import deleteBiometricCode from '../services/identity/delete-biometric-code';
import setBiometricCode from '../services/identity/set-biometric-code';
import useDeviceInfo from './use-device-info';
export default function useBiometricCode(email) {
    var _deviceInfo;
    const deviceInfo = useDeviceInfo();
    const biometricKeychainKey = email && ((_deviceInfo = deviceInfo) === null || _deviceInfo === void 0 ? void 0 : _deviceInfo.uuid) ? `${email}__${deviceInfo.uuid}` : '';
    const [status, setStatus] = useState('unknown');
    useAsync(async ()=>{
        try {
            await isBiometricKeychainAvailable();
        } catch  {
            return setStatus('unsupported');
        }
        if (!biometricKeychainKey) {
            return setStatus('not-enabled');
        }
        try {
            await isSavedInBiometricKeychain(biometricKeychainKey);
            setStatus('enabled');
        } catch  {
            setStatus('not-enabled');
        }
    // the presence/absence of `deviceInfo` indicates if webview API layer is loaded or not
    }, [
        biometricKeychainKey
    ]);
    const disable = useCallback(async ()=>{
        await deleteFromBiometricKeychain(biometricKeychainKey);
        setStatus('not-enabled');
        await deleteBiometricCode();
    }, [
        biometricKeychainKey
    ]);
    const enable = useCallback(async ()=>{
        const code = generateId();
        await saveToBiometricKeychain(biometricKeychainKey, code);
        setStatus('enabled');
        return setBiometricCode({
            code
        });
    }, [
        biometricKeychainKey
    ]);
    const verify = useCallback(async (message)=>verifyInBiometricKeychain(biometricKeychainKey, message), [
        biometricKeychainKey
    ]);
    return {
        status,
        enable,
        disable,
        verify
    };
}
