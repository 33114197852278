import { memo, useLayoutEffect } from 'preact/compat';
import useRouter from './hooks/use-router';
function Redirect({ to, replace }) {
    const { navigate } = useRouter();
    useLayoutEffect(()=>navigate(to, {
            replace
        }), [
        to,
        replace,
        navigate
    ]);
    return null;
}
export default memo(Redirect);
