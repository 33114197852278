export default function getDayPart() {
    const hours = new Date().getHours();
    if (hours <= 12) {
        return 'morning';
    }
    if (hours > 12 && hours <= 18) {
        return 'afternoon';
    }
    return 'evening';
}
