import { AppError } from '../../models/app-error';
import normalizeQrScannerError from './normalize-qr-scanner-error';
export default async function initQrCodeScanner() {
    const { QRScanner } = window;
    if (!QRScanner) {
        throw new AppError({
            code: 'Unsupported'
        });
    }
    return new Promise((resolve, reject)=>{
        QRScanner.prepare((error, status)=>{
            if (error) {
                reject(normalizeQrScannerError(error));
            } else {
                resolve(status);
            }
        });
    });
}
